import LoadableContentSpinner from 'components/Universal/LoadableContentSpinner';
import { DEFAULT } from 'constants/enums';
import { updateCurrency } from 'ducks/currencies';
import HeaderContext from 'providers/HeaderProvider';
import ThemeContext from 'providers/ThemeProvider';
import React, { useContext, useEffect } from 'react';
import Loadable from 'react-loadable';
import { useDispatch } from 'react-redux';

const CoreTemplate = Loadable({
	loader: () => import('components/Templates/advanced/CoreTemplate'),
	loading: LoadableContentSpinner
});

export default function calculatorProposal({ location }) {
	const { setHeader } = useContext(HeaderContext);
	const { setPrimaryColor } = useContext(ThemeContext);
	const dispatch = useDispatch();

	useEffect(() => {
		setHeader({ title: 'Wniosek Limit', isLimit: true });
		dispatch(updateCurrency(DEFAULT.CURRENCY));
		setPrimaryColor('#25848D');
	}, []);

	return <CoreTemplate location={location} isLimit />;
}
